$offwhite: #FFFAFA;
$silver: #2b2b2b;
$gunmetal: #333333;
$vivid: #29ABE2;
$text: #1E1E1E;
$red: #A41034;
$dblue: #00356B;
$robotoc: 'Roboto Condensed', sans-serif;
$roboto: "Roboto", sans-serif;
$dark: $text;
$light: white;

.home-layout {
    .job-listing {
        max-width: 56rem;
        margin: auto;
        padding: 0 1.5rem;
        color: $text;
        margin-top: 2rem;
        h1 {
            font-family: $roboto;
            margin-bottom: 0.25rem;
        }
        h3 {
            font-size: 1.25rem;
            margin-bottom: 0.25rem;
        }
        p, ul {
            line-height: 1.5rem;
            font-size: 1rem;
        }
        ul {
            list-style-position: inside;
        }
        p > strong {
            font-size: 1.25rem;
        }
        .job-header {
            margin-bottom: 2rem;
        }
        div {
            margin-bottom: 1rem;
        }
        @media (min-width: 778px) {
            padding: unset;
        }
        @media (min-width: 960px) {
            h1 {
                font-size: 2rem;
            }
            h2 {
                font-size: 1.75rem;
            }
            h3 {
                font-size: 1.5rem;
                margin-bottom: 0.25rem;
            }
            p, ul {
                line-height: 1.5rem;
                font-size: 1.25rem;
            }
        }
    }

    .job-application {
        width: 23rem;
        padding: 1rem 2rem;
        background-color: white;
        margin: 2rem auto 4rem auto;
        border: solid #ddd 1px;

        @media(min-width: 525px) {
            width: 30rem;
        }

        // .job-application-header {
        //     display: flex;
        //     justify-content: space-between;
        // }

        h1 {
            color: $text;
            margin-top: 1rem;
        }

        hr {
            display: block;
            height: 1px;
            border: 0;
            border-top: 1px solid rgba(30, 30, 30, 0.5);
            margin: 2rem 0;
            padding: 0;
        }

        .image-upload-picture-icon, .file-upload-image,
        .image-upload-picture-loader {
            width: 100%!important;
        }

        textarea {
            border: 1px solid rgba(30, 30, 30, 0.5);
            margin-bottom: 0.25rem;
            resize: vertical;
        }
        .title {
            color: $text;
            font-weight: 100;
            font-size: 1rem;
        }
        input, .mult-select-list-container,
         .mult-select-list-dropdown, 
         .mult-select-list-searchbar input {
            border: 1px solid rgba(30, 30, 30, 0.5)!important;
            border-radius: unset;
            box-shadow: unset;
            color: $text;
            background-color: white;
        }
        .content {
            margin-top: unset;
            min-height: unset;
            margin: unset;
        }
        .upload-area {
            top: unset;
        }
        .question {
            margin-bottom: 0.25rem;
            font-size: 1rem;
            // font-weight: 600;
            color: $text;
        }
        .qa .title-header {
            display: none;
        }
    }
}