$offwhite: #FFFAFA;
$silver: #2b2b2b;
$gunmetal: #333333;
$vivid: #29ABE2;
$text: #1E1E1E;
$red: #A41034;
$dblue: #00356B;
$robotoc: 'Roboto Condensed', sans-serif;
$roboto: "Roboto", sans-serif;
$dark: $text;
$light: white;

body {
    overflow-y: auto !important;
    // overflow: unset!important;\
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.home-layout {
    width: 100%;
    width: 100dvw;
    // height: 100%;
    // height: 100dvh;
    overflow: hidden;
    overflow-x: hidden;
    font-family: $robotoc;
    // background: rgb(204,215,225);
    // background: linear-gradient(90deg, rgba(204,215,225,1) 0%, rgba($offwhite,1) 20%, rgba($offwhite,1) 50%, rgba($offwhite,1) 80%, rgba(204,215,225,1) 100%);
    background-color: $offwhite;

    h1 {
        font-family: $roboto;
    }

    img,
    #text {
        pointer-events: none;
    }

    .main {
        width: 100dvw;
        padding-top: 10dvh;
    }

    .home {
        margin: 0 1.5rem;
    }


    a:link {
        text-decoration: none;
    }

    a:visited {
        text-decoration: none;
    }

    a:hover {
        text-decoration: none;
    }

    a:active {
        text-decoration: none;
    }

    .link:hover,
    .arrow,
    .btn:hover {
        cursor: pointer;
    }

    .arrow {
        z-index: 99;
    }

    .navbar {
        padding-left: 1.5rem;
        min-height: 10dvh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // position: sticky;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 98;
        background: linear-gradient(180deg, rgba(255, 250, 250, 1) 0%, rgba(255, 250, 250, 0.66) 33%, rgba(255, 250, 250, 0.33) 66%, rgba(255, 250, 250, 0) 100%);
    }

    .navbar-block {
        display: none;
        font-family: $roboto;
        font-size: 1rem;
    }

    .navbar-block>* {
        cursor: pointer;
    }

    .cdx-logo {
        display: none;
    }

    .logo img {
        max-height: 2rem;
    }

    .navbar-block a:not(:last-child) {
        display: none;
        color: $text;
        font-weight: 600;
    }

    .navbar-block a:last-child {
        visibility: hidden;
        margin-left: 1rem;
        align-self: center;
    }

    .footer {
        max-height: 6rem;
        height: 100%;
        width: 100%;
        bottom: 0;
        position: relative;
        text-align: center;
        background-color: $gunmetal;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .sns {
        padding-top: 1rem;
    }

    .copyright {
        padding-bottom: 1rem;
        display: flex;
        font-size: 1rem;
        align-items: end;
    }

    .menu-box {
        background-color: $dblue;
        top: 0;
        right: 0;
        display: block;
        position: fixed;
        height: 100dvh;
        width: 20rem;
        z-index: 100;

    }

    .menu-box.inactive {
        right: -20rem;
        transition: all .75s ease-in-out;
    }

    .menu-box.active {
        right: 0;
        transition: all .75s ease-in-out;
    }

    .menu-body {
        min-height: 90dvh;
    }

    .menu-header {
        padding: 1rem 0;
        margin: auto;
        min-height: 10dvh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        justify-content: right;
    }

    .menu-box-contact {
        background-color: #00254d;
        color: #fffafad7;
        font-size: 1.25rem;
        padding: 1rem;
        bottom: 1rem;
        position: absolute;
        width: 85%;
        margin: 0 1.5rem;
    }

    .menu-box-contact ul {
        padding: .5rem;
        list-style: none;
        text-align: center;
    }

    .menu-box-contact li {
        padding: .25rem;
        color: $offwhite;
    }

    .menu-box-contact li a {
        color: inherit;
        text-decoration: none;
    }

    .overlay {
        overflow: hidden;
        left: 0;
        top: 0;
        height: 100dvh;
        width: 100dvw;
        position: fixed;
        background-color: $text;
        opacity: 0.7;
        z-index: 99;
        visibility: visible;
        transition: all 1s ease-in-out;
    }

    .overlay.inactive {
        opacity: 0;
        visibility: hidden;
        transition: all 1s ease-in-out;
    }

    .menu-links {
        font-size: 2rem;
        list-style: none;
        padding: 2rem 0;
        cursor: pointer;
    }

    .menu-links li {
        padding: 0.5rem 1.5rem;
    }

    .menu-links li a {
        display: flex;
        align-items: center;
        opacity: .8;
    }

    .menu-link.selected {
        opacity: 1;
    }

    .menu-links li a svg {
        padding-right: 1rem;
        fill: white;
        height: 2.1rem;
        opacity: .5;
    }

    .menu-link.selected svg {
        opacity: 1;
    }

    .menu-link {
        color: $offwhite;
    }

    .menu-links li:hover {
        background: #00254d;
    }

    .mobile-logo {
        display: inline-block;
        height: 100%;
    }

    .landing h1 {
        font-family: $robotoc;
        color: $text;
        font-size: 2.5rem;
    }

    .landing h2 {
        margin-top: 2rem;
        color: $text;
        font-size: 1.5rem;
    }

    .landing .header {
        margin: auto;
        width: 90%;
        max-width: 56rem;
    }

    .navbar-block a.navlink {
        padding: 0.5rem 1rem;
        position: relative;
    }

    .cdx-bars {
        animation-name: slideIn;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }

    .cdx-bars path {
        animation-name: slideIn;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }


    @keyframes popOutL {
        0% {
            transform: translateX(35%);
        }

        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes popOutR {
        0% {
            transform: translateX(-35%);
        }

        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @for $i from 0 through 3 {
        .cdx-bars path:nth-child(#{$i}n) {
            animation-delay: #{(3 - $i) * 0.1}s;
        }

        .red-bars:nth-child(#{$i}n) {
            animation-delay: #{(3 - $i) * 0.1}s !important;
        }
    }

    @keyframes slideIn {
        0% {
            transform: translateX(200%)
        }

        100% {
            transform: translateX(0)
        }
    }

    @media (min-width: 778px) {
        .landing h2 {
            font-size: 2rem;
        }

        .home {
            margin: 0;
        }

        .navbar {
            padding: 1rem 10dvw;
        }

        .main {
            width: 80dvw;
            margin: auto;
        }

        .landing h1 {
            font-size: 4em;
        }

        .landing .header {
            width: 80%;
        }

        .navbar-block {
            display: flex;
        }

        .navbar-block a:last-child {
            visibility: visible;
        }

        .navbar-block a:not(:last-child) {
            display: inline;
        }

        .navbar-block a.navlink.inactive {
            opacity: 0;
            visibility: hidden;
            transition: all 1s ease-in-out;
        }

        .navbar-block a.navlink.active {
            opacity: 1;
            transition: opacity 1s ease-in-out;
        }

        .logo img {
            max-height: 40px;
        }

        .cdx-logo {
            display: inline;
            object-fit: cover;
            object-position: left;
        }

        .open {
            width: 183px;
            height: 40px;
            transition: width 1s ease-in-out;
        }

        .close {
            width: 52px;
            height: 40px;
            transition: width 1s ease-in-out;
        }

        .cdx-bars,
        .hamburger {
            display: none;
        }
    }

    @media (min-width: 960px) {
        .landing h1 {
            font-size: 5em;
        }
    }

    .landing {
        min-height: 90dvh;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        // padding: 0 0 2rem 0;
    }

    .two-part {
        max-width: 56rem;
        margin: auto;
        min-height: 30dvh;
        display: flex;
        flex-direction: column;
        align-items: center;
        scroll-margin-top: 5rem;
    }

    .left-text>.join-btn {
        font-size: 1.5rem;
        padding: 1rem 2rem;
    }

    .left-text>.join-btn img {
        height: 1.5rem;
        margin-left: .5rem;
        vertical-align: middle;
    }

    .left-text {
        text-align: left;
    }

    .wwd-img,
    .wwd-img-container {
        // display: none;
    }

    .culture-img {
        max-width: 90dvw;
        mask-image: url("../../../public/assets/Hexagon.svg");
        margin: 2rem 0;
    }

    .left-text p,
    .portfolio-text p {
        font-size: min(4dvw, 1rem);
    }

    .left-text p,
    .portfolio-text p {
        margin: 1rem 0;
    }

    .carousel-container {
        white-space: nowrap;
        position: relative;
        padding: 2rem 0 3rem 0;
        overflow-x: hidden;
        width: 100dvw;
        left: -1.5rem;
    }

    @keyframes carousel {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(-100%);
        }
    }

    .carousels {
        animation: 40s carousel infinite linear;
        display: inline-block;
    }

    .logo-item {
        max-width: 12rem;
        min-height: 6rem;
        margin: 0 1.5rem;
    }

    .two-part.culture {
        padding-top: 0;
        width: 100%;
        align-items: unset;
        max-width: unset;
        margin: unset;
    }

    .two-part.wwd {
        color: $text;
    }

    .wwd-img-container {
        scale: .75;
    }

    .culture-img {
        margin: auto !important;
    }


    @media (min-width: 600px) {
        .carousel-container {
            padding-bottom: 2rem 0;
        }

        .wwd-img,
        .culture-img {
            display: block;
            max-height: 360px;
        }

        .wwd-img-container {
            display: block;
        }

        .wwd-img {
            mask-image: url("../../../public/assets/Hourglass_CDX copy.svg");
        }

        .culture-img {
            mask-image: url("../../../public/assets/Hexagon.svg");
        }

        .wwd-svg,
        .culture-svg {
            position: absolute;
            top: 0;
        }

        .wwd-img-container,
        .culture-img-container {
            position: relative;
        }

        .wwd-img-container>img {
            max-height: 480px;
        }

        .two-part.culture img {
            display: block;
            max-height: 480px;
            margin: 0;
        }

        .two-part.culture>.culture-img-container {
            margin: 2rem 0;
        }
    }


    @media (min-width: 778px) {
        .portfolio-text {
            max-width: 56rem;
            margin: 0 auto 4rem auto;
        }

        .wwd-img-container {
            transform-origin: 100% 50%;
        }

        .left-text.wwd {
            margin-right: calc((0.6 - 1) * 13rem);
        }

        .carousel-container {
            padding: 3rem 0;
            overflow: hidden;
            left: -10dvw;
            display: flex;
            align-items: center;
            margin: 0;
        }

        .two-part {
            min-height: 80dvh;
            display: flex;
            flex-direction: row;
            padding: 0;
        }

        .two-part img {
            max-height: 480px;
            margin: 0;
        }

        .two-part.culture {
            flex-direction: column;
            max-width: 56rem;
            margin: auto;
        }

        .logo-item {
            max-width: 16rem;
            min-height: 8rem;
            margin: 0 2rem;
        }

        .portfolio-hover {
            h3 {
                transition: max-height 0.5s ease;
                max-height: 0;
                overflow: hidden;
                visibility: hidden;
            }
        }

        .portfolio-hover:hover {
            h3 {
                max-height: var(--max-height);
                visibility: visible;
            }
            // .services {
            //     visibility: hidden;
            // }
        }
    }

    @media (min-width: 960px) {
        .wwd-img-container {
            scale: 1;
        }

        .left-text.wwd {
            margin-right: unset;
        }

        .two-part.culture>.culture-img-container {
            margin-top: 0;
        }

        .left-text h1,
        .portfolio-text h1,
        .letter-header h1 {
            font-size: 2rem;
        }

        .letter-header {
            margin-bottom: 4.5rem;
        }

        .left-text p,
        .portfolio-text p,
        .letter-text p {
            font-size: 1.25rem;
        }

        .two-part img {
            display: block;
            max-height: 480px;
        }

        .portfolio-hover:hover {
            h3 {
                max-height: var(--max-height);
                visibility: visible;
            }
            .services {
                visibility: hidden;
            }
        }
    }

    .my-masonry-grid {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-left: -2rem;
        width: auto;
    }

    .my-masonry-grid_column {
        padding-left: 2rem;
        background-clip: padding-box;
    }

    .my-masonry-grid_column>div {
        margin-bottom: 2rem;
    }


    .portfolio-box {
        position: relative;
    }

    .portfolio-box img {
        transition: scale 0.2s ease;
        max-width: 100%;
        display: flex;
        justify-content: center;
        box-shadow: 0.1em 0.1em 0.25em #818181;
    }

    .nav-btn {
        display: inline-block;
        width: 7.4rem;
        text-align: center;
    }

    .btn {
        font-family: $roboto;
        background-color: $vivid;
        padding: .5rem 1rem;
        color: white;
        font-weight: 600;
        font-size: 1rem;
    }

    .db-hover {
        background-color: $dblue;
    }

    .portfolio-center {
        text-align: center;
        margin-bottom: 0;
    }

    .btn img {
        margin-left: .5rem;
        height: 1rem;
        vertical-align: middle;
    }

    .carousel__slider {
        padding-right: 4rem;

    }

    .carousel__inner-slide:hover {
        cursor: pointer;
    }

    .carousel__inner-slide {
        position: absolute;
        left: 2rem;
        width: calc(100% - 2rem);
        height: calc(100% - 2rem);
        background-color: #e4e4e4;
        box-shadow: 0.1em 0.1em 0.25em #818181;
        display: flex;
        flex-direction: column;
    }

    .carousel {
        width: 100dvw;
        transform: translateX(-1.5rem);
        margin: 6dvh 0;
        text-align: center;
        color: $text;
    }

    .carousel__dot-group button {
        width: 2dvw;
        height: 2dvw;
        max-width: 16px;
        max-height: 16px;
        border-radius: 50%;
        margin: .1rem;
        border: 0;
        box-shadow: none;
    }

    .carousel__dot--selected {
        background-color: $vivid;
    }

    .portfolio-hover {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        color: $offwhite;
        cursor: pointer;
    }

    .services {
        visibility: hidden;
        transition: visibility 1s;
    }

    // OLD .portfolio-hover:after {
    //     content: '';
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     height: 40%;
    //     background: linear-gradient(180deg, rgba(0, 0, 0, 0), black);
    //     z-index: 0;
    // }

    // .portfolio-hover {
    //     background-color: rgba($dblue, 0.5);
    //     display: flex;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     visibility: none;
    // }
    

    // .portfolio-hover:after {
    //     height: 100%;
    //     background: none;
    //     display: inline;
    //     z-index: 0;
    // }

    .portfolio-title {
        position: absolute;
        margin: auto;
        width: 50%;
        height: auto;
        padding: 1.5rem;
        // top: 1.5rem;
        // left: 1.5rem;
        z-index: 1;
    }

    .portfolio-hover::before {
        content: '';
        z-index: 0;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        background: linear-gradient(118deg, rgb(0 53 107 / 90%) 0%, rgba(0, 0, 0, 0) 34%);
        transition: transform 200ms;
      }
       

    .dark {
        color: white;
        text-shadow: 1px 1px 5px $text;
    }

    .light {
        color: $text;
        text-shadow: 1px 1px 5px white;
    }


    .portfolio-title h1 {
        font-size: 1.5rem;
    }

    .portfolio-title h3 {
        font-size: 1rem;
    }

    // .portfolio-link {
    //     position: absolute;
    //     bottom: 1.5rem;
    //     right: 1.5rem;
    //     z-index: 1;
    // }

    .masonry-container {
        padding-bottom: 2rem;
    }

    @media (min-width: 778px) {
        .portfolio-hover:hover:before {
            transform: scale(1.05);
        }
        .my-masonry-grid {
            padding: 6rem 0;
        }

        .my-masonry-grid.page {
            padding: 0;
            padding-bottom: 10dvh;
        }

        .portfolio-title h1 {
            font-size: 2rem;
        }

        .portfolio-title h3 {
            font-size: 1rem;
        }

        .carousel {
            width: 100dvw;
            transform: translateX(-10dvw);
        }

        .carousel__slider {
            padding-left: 10%;
            padding-right: 10%;
        }

        .carousel__inner-slide {
            left: 1rem;
        }

        .carousel__inner-slide>p {
            font-size: 1.5dvw;
        }

        .carousel__dot-group button {
            width: 1.5dvw;
            height: 1.5dvw;
        }

        .portfolio-box:hover {
            img {
                transition: scale 0.2s ease;
                scale: 1.05;
            }
        }
    }

    @media (min-width: 960px) {

        .carousel__slider {
            padding-left: 20%;
            padding-right: 20%;
        }

        .portfolio-title h1 {
            font-size: 1.5rem;
        }

        .carousel__inner-slide>p {
            font-size: 1.2dvw;
        }

        .carousel__dot-group button {
            width: 1.2dvw;
            height: 1.2dvw;
        }
    }


    @media (min-width: 1200px) {
        .portfolio-btn {
            padding: .75rem 1.5rem;
            font-size: 1.5rem;
            img {
                height: 1.5rem;
            }
        }

        .my-masonry-grid_column {
            padding-left: 4rem;
        }

        .my-masonry-grid_column > div {
            margin-bottom: 4rem;
        }

        .my-masonry-grid {
            margin-left: -4rem;
        }

        .carousel__slider {
            padding-left: 10%;
            padding-right: 10%;
        }

        .portfolio-title h1 {
            font-size: 2rem;
        }

        .portfolio-title h3 {
            // font-size: 2dvw;
        }

        .carousel__inner-slide>p {
            font-size: .5dvw;
        }

        .carousel__dot-group button {
            width: 1.2dvw;
            height: 1.2dvw;
        }

        .portfolio-hover:hover {
            h3 {
                max-height: var(--max-height);
                visibility: visible;
            }
            .services {
                visibility: visible;
            }
        }
    }

    .ending-container {
        position: relative;
        height: 80dvh;
    }

    .ending h1 {
        font-size: min(11dvw, 4rem);
        font-family: $robotoc;
    }

    .ending {
        position: relative;
        text-align: center;
        padding-top: 20dvh;
    }

    .ending-triangle {
        opacity: .3;
        width: 90%;
        max-width: 35rem;
        z-index: 1;
    }

    .ending-buttons {
        display: flex;
        justify-content: center;
        margin: 2rem 0 1rem 0;
    }

    .ending-buttons>.MagneticButton>.btn {
        margin-right: 1rem;
        font-size: 1rem;
        padding: 1rem 1rem;
    }


    .ending-text {
        position: absolute;
        width: 100%;
        font-family: $roboto;
        color: $text;
        z-index: 2;
        bottom: 5dvw;
    }

    .join-btn {
        background-color: $dblue;
    }

    .ending-buttons img {
        margin-left: .5rem;
        height: 1.5rem;
        vertical-align: middle;
    }

    @media (min-width: 525px) {
        .ending h1 {
            font-size: min(12dvw, 5rem)
        }

        .ending-buttons>.MagneticButton>.btn {
            margin-right: 1rem;
            font-size: 1.5rem;
            padding: 1rem 2rem;
        }

        .ending-buttons {
            margin: 3.5rem 0 2rem 0;
        }

        .ending-container {
            margin-bottom: max(5dvh, 4rem);
        }
    }

    @media (min-width: 960px) {
        .ending {
            display: grid;
            justify-content: flex-end;
            // align-items: center;
            text-align: left;
            max-width: 54rem;
            margin: auto;
        }

        .ending-text {
            bottom: unset;
        }

        .ending-buttons {
            margin: 3.5rem 0 0 0;
        }

        .ending-triangle {
            width: 100%;
        }
    }

    .sns {
        margin-bottom: 0.5rem;
    }

    .sns>*:not(:last-child) {
        margin-right: .75rem;
    }



    .social svg {
        pointer-events: unset;
        scale: 1;
        transition: all .2s;
        height: 1.5rem;
        // filter: invert(100%) sepia(43%) saturate(2%) hue-rotate(17deg) brightness(104%) contrast(101%);
    }

    .social svg:hover {
        transition: all .2s;
        scale: 1.2;
        fill: $vivid;
        // filter: brightness(0) saturate(100%);
        // filter: invert(57%) sepia(29%) saturate(1548%) hue-rotate(160deg) brightness(98%) contrast(81%);
    }

    .content {
        min-height: 90dvh;
        margin: auto;
        margin: 0 1.5rem;
        color: $text;
    }

    .article {
        width: 100%;
        height: auto;
    }

    .article>* {
        margin-bottom: .25rem;
    }

    .info-container {
        display: none;
    }

    .info-container-mobile {
        padding: 0 2rem;
        margin-top: 2dvh !important;
    }

    // .info-container.mobile {
    //     display: block;
    //     overflow-x: auto;
    //     width: 100dvw;
    //     margin-left: -2rem;
    //     padding: 0 0 0 2rem;
    //     scrollbar-width: none;
    // }

    .info-buttons.mobile {
        margin: 1rem 0;
    }

    .article img {
        max-width: 100%;
    }

    .info-buttons .btn {
        display: inline-block;
        width: 100%;
        text-align: center;
        padding: 1rem 1rem;
    }

    .info-buttons .btn:first-child {
        padding: 1.5rem 1rem;
        margin-bottom: 1rem;
        font-size: 1.5rem;
        letter-spacing: .5px;

        img {
            height: 1.5rem;
        }
    }

    .xv-hover {
        box-shadow: inset 0 0 0 4px $vivid,
            0 0 1px rgba(0, 0, 0, 0);
        background-color: $offwhite;
        color: $vivid;
    }

    .info-buttons .inverted-btn {
        background-color: $offwhite;
        color: $vivid;
        outline-style: solid;
        outline-offset: -3px;
    }

    .info-sections {
        display: flex;
        width: 120%;
    }

    .info-section {
        margin-right: 2rem;
        width: 33dvw;
    }

    .info-title {
        font-weight: 600;
        font-size: 1rem;
    }

    .info-tags {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;
    }

    .info-tags span {
        width: fit-content;
        display: block;
        background-color: $dblue;
        color: $offwhite;
        padding: .25rem 1rem;
        margin: 0 .5rem .5rem 0;
    }

    .portfolio-container {
        padding-bottom: 6rem;
    }

    .bts {
        display: none;
    }

    .bts {
        text-align: center;
        padding: 3rem 0;

    }

    .bts .btn {
        font-size: 1.5rem;
        padding: 1rem 2rem;
    }


    @media (min-width: 778px) {
        .content {
            margin: 0;
            margin-top: 2rem;
        }

        .info-buttons.mobile {
            display: none;
        }

        .portfolio-container {
            display: flex;
        }

        .article {
            padding-left: 1rem;
        }

        .info-container-mobile {
            display: none;
        }

        .bts {
            display: block;
        }

        .info-container {
            display: block;
            width: 25%;
            min-width: 14rem;
            flex: 0 0 25%;
            padding-right: 1rem;
        }

        .info-buttons .btn {
            display: inline-block;
            width: 100%;
            text-align: center;

            img {
                height: 1rem;
            }
        }

        .bts .btn {
            font-size: 1.2rem;
        }

        .info-buttons .btn:first-child {
            padding: 1.5rem 1rem;
            margin-bottom: 1rem;
            font-size: 1.2rem;
            letter-spacing: .5px;
        }

        .info-sections {
            display: block;
            margin-bottom: 6rem;
            width: 100%;
        }

        .info-section {
            width: auto;
        }

        .info-tags {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1rem;
        }

        .info-title {
            margin-bottom: .25rem;
        }
    }

    @media (min-width: 960px) {

        .info-buttons .btn:first-child,
        .bts .btn {
            font-size: 1.5rem;
            padding: 1rem 2rem;

            img {
                height: 1.5rem;
            }
        }

        .info-buttons {
            max-width: 17rem;
        }

        .article {
            max-width: 48rem;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media (min-width: 1200px) {
        .two-part.culture {
            flex-direction: row;
        }
    }

    .culture .culture-img-container {
        display: block;
        max-height: 480px;
    }

    .content.manifesto {
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        padding-bottom: calc(85dvh/8.2);
        font-size: calc(85dvh/8.2);
        margin: 0;
    }

    .carousel-container.manifesto {
        margin: 0;
        padding: 0;
        left: 0;
    }

    .carousels.manifesto>span:not(.highlight) {
        margin-right: 1rem;
    }

    @keyframes bounce {
        0% {
            left: -15dvw;
        }

        5% {
            visibility: visible;
        }

        70% {
            left: 100%;
        }

        100% {
            visibility: visible;
            left: 50%;
            color: $dblue
        }
    }

    @media (min-width: 778px) {
        .carousel-container.manifesto {
            left: -10dvw;
        }

        .carousel-container.manifesto.play .carousels {
            animation-play-state: paused;
            visibility: hidden;
            opacity: 0;
            // transition: all 0.5s ease-in-out;
        }

        // .carousel-container.manifesto:hover > .highlight-anim {
        //     animation: 2.5s bounce ease-in-out;
        //     animation-fill-mode: forwards;
        // }

        // .highlight-anim {
        //     text-align: center;
        //     font-weight: 600;
        //     position: absolute;
        //     transform: translateX(-50%);
        //     visibility: hidden;
        //     cursor: pointer;
        // }

        .carousel-container.manifesto.play>.center {
            animation: 2s bounce ease-in-out;
            animation-fill-mode: forwards;
            display: inline-block;
        }
    }

    .carousels.manifesto {
        animation: 10s carousel infinite linear;
        animation-fill-mode: forwards;
        margin: 0;
        opacity: 1;
        transition: visibility 1s ease-in-out;
    }

    .carousel-container.manifesto.play .carousels {
        animation-play-state: paused;
        visibility: hidden;
        opacity: 0;
        // transition: all 0.5s ease-in-out;
    }

    .carousel-container.manifesto.play>.center {
        animation: 2s bounce ease-in-out;
        animation-fill-mode: forwards;
        display: inline-block;
    }


    // .carousel-container.manifesto:hover > .highlight-anim {
    //     animation: 2.5s bounce ease-in-out;
    //     animation-fill-mode: forwards;
    // }

    // .highlight-anim {
    //     text-align: center;
    //     font-weight: 600;
    //     position: absolute;
    //     transform: translateX(-50%);
    //     visibility: hidden;
    //     cursor: pointer;
    // }


    .center {
        position: relative;
        display: none;
        transform: translateX(-50%);
        visibility: hidden;
        cursor: pointer;
    }

    .center span {
        margin-right: 1rem;
    }

    .left {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%)
    }

    .right {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%)
    }

    .highlight {
        font-weight: 600;
    }

    hr.solid {
        border-top: 1px solid $text;
        opacity: 0.2;
        display: none;
    }

    hr.vertical {
        border: 1px solid $text;
        opacity: 0.2;
    }

    .contact-container {
        position: relative;
        margin: auto;
        margin-bottom: 2rem;
    }

    .right-divider>* {
        // padding: 2rem 1rem;
        width: 100%;
        max-width: 50rem;
        padding: 2rem 0;
        margin: auto;
    }

    .message-sent {
        display: flex;
        font-size: 1.5rem;
        justify-content: center;
        align-items: center;
        padding: 3rem;
    }

    .image-container {
        width: 100dvw;
        display: flex;
        justify-content: center;
    }

    .image-container img {
        width: 100%;
        min-width: 16rem;
        max-height: 20rem;
        margin-top: -6rem;
        object-fit: cover;
        transform: translateX(-1.5rem);
    }

    .collab {
        opacity: 0.9;
    }

    .text-container {
        padding: 1rem;
    }

    .form>input,
    textarea {
        margin: 0 0 1rem 0;
        border-radius: 0 !important;
        border: 1px solid rgba($text, 0.5) !important;
        box-shadow: none !important;
        background-color: white !important;
    }

    .submit {
        margin: 0 0 1rem 0;
        border-radius: 0 !important;
        border: 1px solid rgba($text, 0.5) !important;
    }

    .button-container {
        width: 100%;
        text-align: center;
        padding-bottom: 6rem;
    }

    .contact {
        border: 0px !important;
        cursor: pointer;
        // position: absolute;
        margin-left: auto;
        margin-right: auto;
        // left: 0;
        // right: 0;
        // bottom: -1rem;
    }

    .submit {
        width: max-content;
        border: 0 !important;
        background-color: $dblue;
        margin: 0;
    }

    .submit-container {
        width: 100%;
        text-align: center;
    }

    .cover {
        width: 100dvw;
        height: 100dvh;
        position: fixed;
        background: #1e1e1eb4;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        transition: visibility .5s, opacity .5s;
    }

    .popup-body {
        position: absolute;
        width: 75dvw;
        max-width: 60rem;
        padding: 1rem;
        background: #ffff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
    }

    .popupFadeIn {
        animation-name: popupFadeIn;
    }

    @keyframes popupFadeIn {
        from {
            opacity: 0;
            transform: translate3d(-150%, -50%, 0);
        }

        to {
            opacity: 1;
            transform: translate3d(-50%, -50%, 0);
        }
    }

    .popup-body h1 {
        font-size: 2rem;
    }

    .drop-words {
        font-size: 1.5rem;
        white-space: normal;
    }

    @media (min-width: 778px) {
        .popup-body {
            padding: 2rem;
        }

        .form>input,
        textarea,
        .submit {
            margin: 0 0 2rem 0;
        }

        .contact {
            margin-left: auto;
            margin-right: auto;
            left: auto;
            right: 2rem;
        }

        // .right-divider > * {
        //     padding: 1rem 1rem 2rem 1rem;
        // }

        hr.vertical {
            width: 0;
            border: 1px solid $text;
            opacity: 0.2;
        }

        .contact-form {
            display: flex;
            // outline: 1px solid black;
            box-shadow: 0.01em 0.01em 0.25em #818181;
            height: 100%;
            margin: 0 auto;
            max-width: 60rem;
        }

        .contact-form>* {
            width: 50%;
        }

        .contact-container {
            max-width: 60rem;
        }

        .left-divider {
            display: flex;
            flex-direction: column;
        }

        .image-container {
            width: 100%;
            padding-bottom: 0;
            height: 100%;
            display: flex;
            justify-content: center;
        }

        .image-container img {
            width: 100%;
            height: 100%;
            margin: auto;
            object-fit: cover;
            max-height: 100%;
            margin: auto;
            transform: translateX(0)
        }

        .text-container {
            height: 75%;
            padding: 2rem;
        }

        .popup-body h1 {
            font-size: 3.5rem;
        }

        .drop-words {
            font-size: 2rem;
        }
    }

    @keyframes drop-anim {
        0% {
            transform: translateY(-100%) scaleY(0.9);
            opacity: 0;
        }

        5% {
            opacity: .7;
        }

        50% {
            transform: translateY(0px) scaleY(1);
            opacity: 1;
        }

        65% {
            transform: translateY(-10%) scaleY(.9);
            opacity: 1;
        }

        75% {
            transform: translateY(-15%) scaleY(.9);
            opacity: 1;
        }

        100% {
            transform: translateY(0px) scaleY(1);
            opacity: 1;
        }
    }

    .drop-words {
        opacity: 0;
    }

    .drop-animation-container {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;
        font-size: 2rem;
    }

    .selected {
        font-weight: 600;
    }

    @keyframes bounce-anim {
        0% {
            transform: translateY(50%)
        }

        40% {
            transform: translateY(25%)
        }

        60% {
            transform: translateY(25%)
        }

        100% {
            transform: translateY(50%)
        }
    }

    @keyframes bounce-anim-rev {
        0% {
            transform: translateY(0%)
        }

        40% {
            transform: translateY(25%)
        }

        60% {
            transform: translateY(25%)
        }

        100% {
            transform: translateY(0%)
        }
    }

    #red-arrow {
        animation: 1.5s bounce-anim ease-in-out infinite;
        transform-origin: center;
    }

    #dblue-arrow {
        animation: 1.5s bounce-anim-rev ease-in-out infinite;
        transform-origin: center;

    }

    #east {
        transform: rotate(270deg);
    }

    #west {
        transform: rotate(90deg);
    }

    .landscape-mode {
        height: 100dvh;
        text-align: center;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
    }

    .landscape-mode>* {
        flex: 0 0 100%;
    }

    @keyframes rotate-anim {
        0% {
            transform: rotate(-90deg)
        }

        10% {
            transform: rotate(-180deg);
            scale: 1.2
        }

        90% {
            transform: rotate(-180deg);
            scale: 1.2
        }

        100% {
            transform: rotate(-90deg)
        }
    }

    .landscape-svg-container {
        display: flex;
        gap: 2rem;
        justify-content: center;
        transform-origin: center;
        animation: 5s rotate-anim ease-in-out infinite;
        animation-fill-mode: forwards;
    }

    .error-none {
        display: none;
    }

    .form-label {
        display: block;
        font-size: 1rem;
        margin-bottom: .25rem;
        font-family: $robotoc;
    }

    .form span {
        margin-bottom: .25rem;
    }

    .navlink.active {
        letter-spacing: 0;
    }

    .navlink.active:after,
    .navlink.active:before {
        backface-visibility: hidden;
        border: 1px solid rgba($vivid, 0);
        bottom: 0px;
        content: "";
        display: block;
        margin: .25rem auto;
        position: relative;
        transition: all 280ms ease-in-out;
        width: 0;
    }

    .navlink.active:hover:after,
    .navlink.active:hover:before {
        backface-visibility: hidden;
        border-color: $vivid;
        transition: width 350ms ease-in-out;
        width: 90%;
    }

    .navlink.active:hover:before {
        bottom: auto;
        top: 0;
        width: 90%;
    }

    .card-panel {
        display: flex;
        flex-direction: column;
        max-width: 22.5rem;
        // height: 100%;
        margin: 0 1rem;
        background-color: #e2e2e2;
        position: relative;
        box-shadow: 0.1em 0.1em 0.25em #818181;
        cursor: grab;
    }

    .card-panel:active {
        cursor: grabbing;
    }

    .flicking-viewport {
        width: 100dvw !important;
        color: $text;
        transform: translateX(-1.5rem);
        margin-top: 4dvh;
    }

    .flicking-pagination-bullet-active {
        background-color: $vivid;
    }

    .flicking-pagination {
        position: relative;
        bottom: unset;
        margin: 1.5rem 0;
    }

    .flicking-pagination-bullet {
        width: .75rem;
        height: .75rem;
        margin: 0 2px;
    }

    .review-header__image {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $dblue;
    }

    .review-header__text {
        color: $offwhite;
        text-align: center;
        transform: translateY(2.5rem);
        // padding-left: 1rem;
    }

    .review-header {
        // margin: 1rem 1rem .5rem 1rem;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        background: $dblue;
        flex-direction: column;
    }

    .logo__container {
        text-align: right;
    }

    .review-header__logo {
        // margin-top: 1rem;
        height: 2.5rem;
        margin: 1rem 2rem 1rem 0;
    }

    .review-header__text h1 {
        font-size: 2rem;
        font-family: $robotoc;
    }

    .review-header__text h3 {
        margin-top: 0.25rem;
        font-weight: 100;
        font-size: 1.25rem;
    }

    .triangle {
        object-fit: contain;
        aspect-ratio: 420.23/326.2;
        width: 20rem;
        mask-image: url("../../../public/assets/cdx/breakdown/background.svg")
    }

    .circular_image {
        transform: translateY(50%);
        object-fit: cover;
        width: 8rem;
        height: 8rem;
        // border-radius: 3%;
        // border-radius: 50%;
        overflow: hidden;
        // margin-right: 1rem;
        box-shadow: 0.1em 0.1em 0.25em #818181;
    }

    .qt-container {
        margin: 0 2rem;
        font-size: .8rem;
        flex: 1;
        display: flex;
        margin-top: 6rem;
        // align-items: center;
    }

    .qt {
        display: flex;
        align-items: center;
    }

    .qt-begin,
    .qt-end {
        position: absolute;
        height: 2.5rem;
        z-index: 0;
        opacity: .1;
    }

    .qt-begin {
        left: 2rem;
        transform: translateY(-1rem);

    }

    .qt-end {
        right: 1rem;
        transform: translateY(-1rem);
    }

    .review-quote {
        padding: 0 1rem;
        font-family: $roboto;
        font-style: italic;
    }

    @media (min-width: 525px) {
        .card-panel {
            max-width: 25rem;
        }

        .review-header__text {
            transform: translateY(3rem);
        }

        .circular_image {
            height: 10rem;
            width: 10rem;
        }

        .flicking-pagination-bullet {
            width: 1rem;
            height: 1rem;
            margin: 0 2px;
        }

        .qt-container {
            margin-top: 7rem;
            font-size: .9rem;
        }
    }

    @media (min-width: 778px) {
        .review-header__logo {
            height: 3rem;
        }

        .review-header__text h1 {
            font-size: 2.5rem;
        }

        .review-header__text h3 {
            font-size: 1.5rem;
        }

        .review-header__text {
            transform: translateY(4rem);
        }

        .circular_image {
            height: 13rem;
            width: 13rem;
        }

        .card-panel {
            max-width: 30rem;
            margin: 0 2rem;
        }

        .flicking-viewport {
            transform: translateX(-10dvw);
        }

        .flicking-pagination {
            margin: 2rem 0;
        }

        .review-header {
            // margin: 2rem 2rem 1rem 2rem;
        }

        .qt-container {
            margin-top: 9rem;
            font-size: 1rem;
        }

        .qt-begin,
        .qt-end {
            position: absolute;
            height: 3rem;
        }

        .qt-begin {
            left: 2.5rem;
            transform: translateY(-1.25rem);
        }

        .qt-end {
            right: 2rem;
        }

        .review-quote {
            padding: 0 2rem;
        }
    }

    @media (min-width: 960px) {
        .MagneticButton {
            display: flex;
            user-select: none;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 4rem;
            transform: translateX(-4rem);
            position: relative;
        }

        .ending-text {
            transform: translateY(calc(20dvh + 20%));
        }

        .ending-buttons {
            margin: 0;
            justify-content: unset;
        }

        .ending-buttons>.MagneticButton>.btn {
            margin-right: 1rem;
            font-size: 1.5rem;
            padding: 1rem 2rem;
        }

        .ending-buttons>.MagneticButton:last-child {
            transform: translateX(-8rem);
            padding-left: 0;
        }
    }

    .animate__animated.animate__fadeIn {
        --animate-duration: 3s;
    }

    @keyframes border-transform {

        0%,
        100% {
            border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
        }

        14% {
            border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
        }

        28% {
            border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
        }

        42% {
            border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
        }

        56% {
            border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
        }

        70% {
            border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
        }

        84% {
            border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
        }
    }

    .progress-wrap {
        position: fixed;
        right: 2rem;
        bottom: 2rem;
        height: 46px;
        width: 46px;
        cursor: pointer;
        display: block;
        box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
        border-radius: 50px;
        z-index: 97;
        opacity: 0;
        visibility: hidden;
        transform: translateY(15px);
        transition: all 200ms linear;
    }

    .progress-wrap::before {}

    .progress-wrap.active-progress {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    .progress-arrow {
        position: absolute;
        text-align: center;
        color: rgba($vivid, 0.8);
        left: calc((46px - 30px) / 2);
        top: calc((46px - 30px) / 2);
        height: 30px;
        width: 30px;
        cursor: pointer;
        display: block;
        z-index: 2;
        transition: all 200ms linear;
    }

    .progress-wrap:hover .progress-arrow {
        color: rgba($vivid, 1);
    }

    .progress-wrap svg path {
        fill: none;
    }

    .progress-wrap svg.progress-circle path {
        stroke: rgba($vivid, 1);
        stroke-width: 4;
        box-sizing: border-box;
        transition: all 200ms linear;
    }

    .v-hover,
    .db-hover,
    .xv-hover {
        transition: 0.25s;
    }

    .v-hover:hover {
        transition: 0.25s;
        box-shadow: inset 0 0 0 4px $vivid,
            0 0 1px rgba(0, 0, 0, 0);
        // box-shadow: 0 0 0 1px white, 0 0 0 2px $vivid, 0 0 0 5px $vivid, 0 0 0 6.5px $vivid, 0 0 0 7px $vivid;
        background: $offwhite;
        color: $vivid;
    }

    .db-hover:hover {
        transition: 0.25s;
        box-shadow: inset 0 0 0 4px $dblue,
            0 0 1px rgba(0, 0, 0, 0);
        // box-shadow: 0 0 0 1px white, 0 0 0 2px $dblue, 0 0 0 5px $dblue, 0 0 0 6.5px $dblue, 0 0 0 7px $dblue;
        background: $offwhite;
        color: $dblue;
    }

    .xv-hover:hover {
        transition: 0.25s;
        box-shadow: inset 0 0 0 4px $vivid,
            0 0 1px rgba(0, 0, 0, 0);
        // box-shadow: 0 0 0 1px white, 0 0 0 2px $vivid, 0 0 0 5px $vivid, 0 0 0 6.5px $vivid, 0 0 0 7px $vivid;
        background: $vivid;
        color: $offwhite;
    }


    .casestudy-btns {
        cursor: pointer;
    }

    .casestudy-btns a {
        display: inline-flex;
        align-items: center;
        // margin-bottom: 1rem;
        // text-decoration: underline;
        text-decoration-thickness: 0.15em;
        text-decoration-color: $dblue;

        p {
            font-size: 1rem;
            font-family: $roboto;
            color: $dblue;
            font-weight: 600;
            margin-left: .5rem;
        }

        img {
            height: 1rem;
        }
    }

    .landing-container {
        // background-color: $offwhite;
        width: 100dvw;
        min-height: 190dvh;
        transform: translateX(-1.5rem);
    }

    .logo-scroll-container {
        width: 100dvw;
        // height: 100dvh;
        position: absolute;
        // transition: all .5s;
    }

    .logo-scroll {
        // position: absolute;
        -webkit-backface-visibility: hidden;
        left: 0;
        right: 0;
    }

    .ls-center {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .logo-scroll-container {
        height: 100dvh;
    }

    .test1 {
        background-color: $red;
        background: url(https://www.xfive.co/_next/static/media/x-arm-1.34612053.svg);
        aspect-ratio: 621/905;
        // width: 6rem;
        height: 100dvh;
        right: calc(50% - 15.91vh);
        bottom: 0;
        position: absolute;
        z-index: 1;
    }

    .test2 {
        background-color: $vivid;
        background: url(https://www.xfive.co/_next/static/media/x-arm-2.dd7c841b.svg);
        aspect-ratio: 419/373;
        // width: 6rem;
        height: 40dvh;
        left: calc(50% + 6.62vh);
        bottom: 60dvh;
        position: absolute;
        z-index: 1;
    }

    .test3 {
        background-color: $text;
        background: url(https://www.xfive.co/_next/static/media/x-arm-3.e62f7363.svg);
        aspect-ratio: 419/373;
        // width: 6rem;
        height: 40dvh;
        left: calc(50% + 6.62vh);
        bottom: 0;
        position: absolute;
        z-index: 1;
    }

    .bg,
    .bot,
    .c,
    .stripes {
        aspect-ratio: 420.23/326.2;
        // height: 75dvh;
        width: 90dvw;
        z-index: 1;
        position: absolute;
        background-repeat: no-repeat;
        left: 0;
        right: 0;
        // bottom: 30dvh;
        transform: translateY(-45%);
        margin: auto;
    }
    
    .bg {
        width: 91dvw;
    }

    .logo-scroll {
        opacity: .25;
    }

    .cdxtext-container {
        // position: absolute;
        // left: 0;
        // right: 0;
        // bottom: 24dvh;
        margin: auto;
        display: flex;
        justify-content: center;
    }

    .cdxtext {
        aspect-ratio: 1342.6/237.53;
        // height: 15dvh;
        width: 80dvw;
        z-index: 1;
        background-repeat: no-repeat;
        left: 0;
        right: 0;
        margin: auto;
    }

    @media (min-width: 525px) {
        .cdxtext {
            aspect-ratio: 1342.6/237.53;
            // height: 15dvh;
            width: 80dvw;
            // max-width: 60rem;
        }
    }

    @media (min-width: 778px) {
        .landing-container {
            transform: translateX(-10dvw);
        }

        .cdxtext,
        .glitch:before,
        .glitch:after {
            aspect-ratio: 1342.6/237.53;
            // height: 15dvh;
            width: 80dvw;
            // max-width: 60rem;
        }
    }

    @media (min-width: 960px) {
        .bg,
        .bot,
        .c,
        .stripes {
            aspect-ratio: 420.23/326.2;
            height: 75dvh;
            width: unset;
            // bottom: 5dvh;
        }

    }

    @media (min-width: 1200px) {
        .cdxtext,
        .glitch:before,
        .glitch:after {
            width: 60dvw;
        }
    }


    .bg {
        background: url('../../../public/assets/cdx/breakdown/bg2.svg');
    }

    .bot {
        background: url('../../../public/assets/cdx/breakdown/bot.svg');
    }

    .c {
        background: url('../../../public/assets/cdx/breakdown/c.svg');
    }

    .stripes {
        background: url('../../../public/assets/cdx/breakdown/stripes.svg');
    }


    @keyframes glitchBottom {

        2%,
        64% {
            transform: translate(-2px, 0);
        }

        4%,
        60% {
            transform: translate(-2px, 0);
        }

        62% {
            transform: translate(-22px, 5px) skew(21deg);
        }
    }

    @keyframes rubberBand {
        from {
            transform: scale3d(1, 1, 1);
        }

        30% {
            transform: scale3d(1.25, 0.75, 1);
        }

        40% {
            transform: scale3d(0.75, 1.25, 1);
        }

        50% {
            transform: scale3d(1.15, 0.85, 1);
        }

        65% {
            transform: scale3d(0.95, 1.05, 1);
        }

        75% {
            transform: scale3d(1.05, 0.95, 1);
        }

        to {
            transform: scale3d(1, 1, 1);
        }
    }

    .cls-1 {
        fill: #333;
        // stroke-width: 3;
        stroke: #333;
        // transition: stroke-dashoffset 250ms linear;
        // transition: fill 500ms linear;
    }

    @keyframes stroke {
        0% {
            fill: rgba(72, 138, 20, 0);
            stroke: #333;
            stroke-dashoffset: 25%;
            stroke-dasharray: 0 50%;
            stroke-width: 3;
        }

        70% {
            fill: rgba(72, 138, 20, 0);
            stroke: #333;
        }

        80% {
            fill: rgba(72, 138, 20, 0);
            stroke: #333;
            stroke-width: 4;
        }

        100% {
            fill: rgba(#333, 1);
            stroke: #333;
            stroke-dashoffset: -25%;
            stroke-dasharray: 50% 0;
            stroke-width: 0;
        }
    }


    @keyframes tilt-shaking {
        0% {
            transform: translateY(-20%) rotate(0deg);
        }

        25% {
            transform: translateY(-20%) rotate(-10deg);
        }

        50% {
            transform: translateY(-20%) rotate(0deg);
        }

        75% {
            transform: translateY(-20%) rotate(10deg);
        }

        100% {
            transform: translateY(-20%) rotate(0deg);
        }
    }

    @keyframes scale-up {
        0% {
            scale: 1;
        }

        100% {
            scale: 4;
        }
    }

    .juice:hover {
        animation: scale-up .5s forwards, tilt-shaking .3s infinite;
        // transition: scale 1s;
    }

    .letter {
        margin: 0 1.5rem;
        padding-bottom: 8rem;
        color: $text;
    }

    .letter-text {
        font-size: 1rem;
    }

    .letter-header h1 {
        text-align: center;
        font-size: 2em;
    }

    .letter-header {
        margin-bottom: 2rem;
    }

    .letter-text p:not(:first-child) {
        margin-top: 1.5rem;
    }

    .signature {
        margin-top: 1.5rem;
        margin-bottom: 4rem;
        width: 10rem;
    }

    @media (min-width: 778px) {
        .letter {
            max-width: 56rem;
            margin: auto;
        }

        .letter h1 {
            font-size: 2rem;
        }

        .letter-text {
            font-size: 1.25rem;
        }

        .signature {
            width: 15rem;
        }
    }

    @media (min-width: 1280px) {
        .letter {
            // margin-left: 25%;
            // margin-right: 25%;
        }
    }


    @keyframes moveUp {
        100% {
            transform: translateY(-95px);
        }
    }

    @keyframes moveDown {
        100% {
            transform: translateY(95px);
        }
    }

    .triangle-container {
        display: flex;
        align-items: center;
        width: 422.5px;
        height: 554px;
    }

    .hg {
        display: flex;
        flex-direction: column;
    }

    .one {
        background: url('../../../public/assets/triangle/tri-t.svg');
    }

    .two {
        background: url('../../../public/assets/triangle/tri-b.svg');
    }

    .thr {
        background: url('../../../public/assets/triangle/dia.svg');
    }

    .one,
    .two {
        aspect-ratio: 845/732;
        height: 366px;
        width: 422.5px;
        object-fit: cover;
    }

    .one {
        object-position: right 10%;
        transform: translateY(95px);
        // animation: moveDown 0.5s linear forwards;
        mask-image: url("../../../public/assets/triangle/tri-t.svg")
    }

    .two {
        transform: translateY(-95px);
        // animation: moveUp 0.5s linear 0.5s forwards;
        mask-image: url("../../../public/assets/triangle/tri-b.svg")
    }

    .thr {
        position: relative;
        opacity: 0;
        aspect-ratio: 220/380;
        height: 190px;
        width: 110px;
        object-fit: cover;
        transform: translateX(-267px);
        transition: opacity 1s;
        transition-delay: 0.5s;
        mask-image: url("../../../public/assets/triangle/dia.svg")
    }

    .cf-form {
        height: 35rem;
        // transition: height 1s;
    }

    // .cf-form.sent {
    //     height: 16rem;
    // }

    .cf {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 23rem;
        z-index: 1;
        margin: auto;
        perspective: 60rem;
    }

    .side {
        background-color: white;

        &:nth-of-type(1) {
            padding: 1rem 2rem 0 2rem;
            border: solid #ddd 1px;
            border-bottom: 0px;
        }

        &.side:nth-of-type(2) {
            padding: 0 2rem 1rem 2rem;
            border: solid #ddd 1px;
            border-top: 0px;
        }
    }

    .envelope {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
    }

    .envelope.front {
        width: 10rem;
        height: 6rem;
        border-radius: 0 0 1rem 1rem;
        overflow: hidden;
        z-index: 0;
        opacity: 0;
    }

    .envelope.front::before,
    .envelope.front::after {
        position: absolute;
        display: block;
        width: 12rem;
        height: 6rem;
        background-color: $vivid;
        transform: rotate(30deg);
        transform-origin: 0 0;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
        content: '';
    }

    .envelope.front::after {
        right: 0;
        transform: rotate(-30deg);
        transform-origin: 100% 0;
    }

    .envelope.back {
        top: -4rem;
        width: 10rem;
        height: 10rem;
        overflow: hidden;
        z-index: 0;
        opacity: 0;
        transform: translateY(-6rem);

        &::before {
            display: block;
            width: 10rem;
            height: 10rem;
            background-color: $vivid;
            border-radius: 1rem;
            content: '';
            transform: scaleY(0.6) rotate(45deg)
        }
    }

    .result-message {
        opacity: 0;
        transition: all 0.3s 2s;
        transform: translateY(9rem);
        text-align: center;
        font-family: $robotoc;
        //   z-index: -9999;
    }

    .sent {
        & .cf {
            animation: scaleLetterSmall 1s forwards ease-in
                /*,
               pushLetter 0.5s 1.33s forwards ease-out*/
            ;
        }

        & .side:nth-of-type(1) {
            transform-origin: 0 100%;
            animation: closeLetter 0.66s forwards ease-in;
        }

        & .side:nth-of-type(2) {
            height: 12rem;
        }

        & .side:nth-of-type(2) label,
        .side:nth-of-type(2) textarea,
        .side:nth-of-type(2) input {
            animation: fadeOutText 0.66s forwards linear;
        }

        & button {
            background-color: rgba(78, 94, 114, 0.2);
        }

        & .envelope {
            animation: fadeInEnvelope 0.5s 1.33s forwards ease-out;
        }

        & .envelope.front {
            z-index: 97;
        }

        & .result-message {
            opacity: 1;
            transform: translateY(12rem);
        }

        & small {
            opacity: 0;
        }
    }

    .centered {
        position: absolute;
        left: 0;
        right: 0;
        margin: 1rem auto;
    }

    @keyframes closeLetter {
        50% {
            transform: rotateX(-90deg);
        }

        100% {
            transform: rotateX(-180deg);
            backface-visibility: hidden;
        }
    }

    @keyframes fadeOutText {
        49% {
            opacity: 1;
        }

        50% {
            opacity: 0;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes fadeInEnvelope {
        0% {
            opacity: 0;
            transform: translateY(8rem);
        }

        /*90% {opacity: 1; transform: translateY(4rem);}*/
        100% {
            opacity: 1;
            transform: translateY(5rem);
        }
    }

    @keyframes scaleLetter {
        66% {
            transform: translateY(-8rem) scale(0.5, 0.5);
        }

        75% {
            transform: translateY(-8rem) scale(0.5, 0.5);
        }

        90% {
            transform: translateY(-8rem) scale(0.3, 0.5);
        }

        97% {
            transform: translateY(-8rem) scale(0.33, 0.5);
        }

        100% {
            transform: translateY(-8rem) scale(0.3, 0.5);
        }
    }

    @keyframes scaleLetterSmall {
        66% {
            transform: translateY(-7rem) scale(0.5, 0.5);
        }

        75% {
            transform: translateY(-7rem) scale(0.5, 0.5);
        }

        90% {
            transform: translateY(-7rem) scale(0.4, 0.5);
        }

        97% {
            transform: translateY(-7rem) scale(0.44, 0.5);
        }

        100% {
            transform: translateY(-7rem) scale(0.4, 0.5);
        }
    }

    @keyframes scaleLetterMedium {
        66% {
            transform: translateY(-7rem) scale(0.5, 0.5);
        }

        75% {
            transform: translateY(-7rem) scale(0.5, 0.5);
        }

        90% {
            transform: translateY(-7rem) scale(0.3, 0.5);
        }

        97% {
            transform: translateY(-7rem) scale(0.33, 0.5);
        }

        100% {
            transform: translateY(-7rem) scale(0.3, 0.5);
        }
    }

    @media (min-width: 525px) {
        .cf {
            width: 30rem;
        }

        .sent {
            & .cf {
                animation: scaleLetterMedium 1s forwards ease-in;
            }
        }
    }

    @media (min-width: 778px) {
        .sent {
            & .cf {
                animation: scaleLetter 1s forwards ease-in;
            }
        }
    }

    .contact-content .left-text {
        text-align: center;
        max-width: 56rem;
        margin: auto;
    }

    .left-text.contact-bottom {
        margin: 2rem auto;
        // text-align: left;
    }

}